import React from "react";
import RidersCoffeeLogo from "../../assets/icons/logo_rc_full.svg";
import { Link } from "react-router-dom";
import NavBurger from "./navBurger";
import NavMenu from "./navMenu";

const NavContainer: React.FC = () => {
  const [menuActive, setMenuActive] = React.useState(false);
  const toggleMenuActive = () => {
    if (menuActive) {
      setMenuActive(false);
    } else {
      setMenuActive(true);
    }
  };
  return (
    <>
      <nav className=" z-50 fixed flex flex-row justify-between items-center top-0 w-screen h-[5rem] pl-4 pr-4 bg-gray-200  bg-opacity-30 backdrop-blur-xl">
        <NavBurger onClick={toggleMenuActive} />
        <img
          src={RidersCoffeeLogo}
          alt="Riders' Coffee logo"
          className="max-h-16 w-auto"
        />
        <Link
          to="/app"
          className=" pl-3 pr-3 sm:w-40 h-8 bg-gradient-to-r from-yellow-900 to-gray-900 text-center flex flex-col items-center justify-center text-white rounded-xl font-serif transition-all hover:scale-105 hover:-translate-y-1 "
        >
          DÉMARRER
        </Link>
      </nav>
      <NavMenu active={menuActive} />
    </>
  );
};

export default NavContainer;
