import React from "react";
import Helmet from "react-helmet";

const SignUp: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>JoinUs | Inscription</title>
        <meta
          name="description"
          content="Bienvenue sur JoinUs, inscris-toi sur notre site et retrouve des guides et personnes pour aller te balader !"
        />
      </Helmet>
      <main className="container background-image-1 bg-fixed bg-clip-border bg-cover flex flex-col justify-start items-center w-view gap-12">
        <h1 className="text-5xl text-blue-700 font-bold mt-12">
          Inscris toi !
        </h1>
        <div className=" bg-gray-100 flex flex-col items-center w-9/12 rounded-2xl shadow-xl gap-12 mb-12">
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5 mt-12"
            placeholder="Nom"
          />
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5"
            placeholder="Prénom"
          />
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5"
            placeholder="Pseudo"
          />
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5"
            placeholder="Email"
          />
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5"
            placeholder="Password"
          />
          <input
            type="text"
            className="bg-white w-8/12 h-12 rounded-xl p-5"
            placeholder="Repeat password"
          />
          <button className="font-bold text-white bg-blue-700 hover:bg-blue-500 w-4/12 h-14 rounded-2xl mb-12">
            Valider
          </button>
        </div>
      </main>
    </>
  );
};

export default SignUp;
