import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineConstruction } from "react-icons/md";
import LogoRCFull from "../assets/icons/logo_rc_full.svg";

const Component: React.FC = () => {
  return (
    <main className="bg-beige-100 flex justify-around h-screen items-center">
      <div className="mr-2 ml-2 flex flex-col items-center bg-white rounded-3xl justify-around p-4 shadow-lg">
        <MdOutlineConstruction className="w-40 h-40 text-yellow-900" />
        <div className="text-xl m-12">
          Cet endroit est encore en cours de construction...
        </div>
        <Link to="/">
          <div className=" pl-12 pr-12 md:w-96 h-12 flex items-center justify-around font-title text-xl text-gray-300 animate-bounce rounded-3xl bg-yellow-800">
            Revenir a l'accueil
          </div>
        </Link>
        <img className="w-40 h-40" src={LogoRCFull} alt="riders coffee" />
      </div>
    </main>
  );
};

export default Component;
