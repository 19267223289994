import React from "react";

interface navBurgerProps {
  onClick: () => void;
}

const navBurger: React.FC<navBurgerProps> = (props: navBurgerProps) => {
  return (
    <div
      className="flex flex-col justify-around h-3/6 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="bg-gray-900 w-12 h-1 rounded-full"></div>
      <div className="bg-gray-900 w-10 h-1 rounded-full"></div>
      <div className="bg-gray-900 w-8 h-1 rounded-full"></div>
    </div>
  );
};

export default navBurger;
