import React from "react";
import { Routes, Route } from "react-router-dom";

//Import pages component
//! Please always do so that the file architecture in ../pages is the same as the url architecture
import AuthRouter from "./auth";
import AppRouter from "./app";
import NoMatch from "../pages/404";
import AboutPage from "../pages/about";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AboutPage />} />
      <Route path="/auth/*" element={<AuthRouter />} />
      <Route path="/app/*" element={<AppRouter />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default Router;
