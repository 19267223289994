import React from "react";
import { Routes, Route } from "react-router-dom";

import NoMatch from "../pages/404";

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AppRouter;
