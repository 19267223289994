import React from "react";
import { Routes, Route } from "react-router-dom";

//Import the various component pages from the ../pages/auth folder
import Login from "../pages/auth/login";
import SignUp from "../pages/auth/sign-up";

const AuthRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="sign-up" element={<SignUp />} />
    </Routes>
  );
};

export default AuthRouter;
