import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const handleLogin = (e: any) => {
    e.preventDefault();
    //TODO perform validations, send data to the backend and create the user session
  };

  return (
    <>
      <Helmet>
        <title>JoinUS | Rejoins-nous !</title>
        <meta
          name="description"
          content="Bienvenue sur JoinUs, le site qui te permet de trouver des sorties de groupe, pour découvrir en vélo les plus belles régions de France"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <main className="flex background-image-1 bg-fixed bg-clip-border bg-cover justify-center items-center w-view h-screen overflow-hidden">
        <div className="container mx-auto  w-6/12 h-96 mb-12">
          <h1 className="text-center font-bold text-5xl mb-10">
            Bienvenue sur
            <span className="text-blue-700">JoinUs</span>
          </h1>
          <form
            className="transition all duration-1000 container bg-gray-100 rounded-3xl h-full flex flex-col gap-9 items-center justify-center shadow-xl"
            onSubmit={handleLogin}
          >
            <input
              type="text"
              name="id"
              placeholder="Identifiant"
              className="bg-white w-8/12 h-12 rounded-xl p-5"
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="bg-white w-8/12 h-12 rounded-xl p-5"
            />
            <button
              type="submit"
              className="transition-all duration-1000 font-bold text-white bg-blue-700 hover:bg-blue-500 w-4/12 h-14 rounded-2xl"
            >
              Login
            </button>
            <div>
              Tu n'as pas encore de compte ?
              <Link to="/auth/sign-up">
                <span className="text-blue-700 font-bold cursor-pointer">
                  Rejoins nous !
                </span>
              </Link>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default Login;
